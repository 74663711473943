import axios from "axios";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Stack,
  Table,
  Tooltip,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faDownload,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { config, server } from "../env";
import { toast } from "react-toastify";
import Header from "../components/Header";

const CsvOrders = () => {
  const [loader, setLoader] = useState(false);
  const [csvpaydisabled, setCsvpaydisabled] = useState(true);
  const [csvPrice, setCsvPrice] = useState(0);
  const [validateLoader, setValidateLoader] = useState(false);
  const [CSV, setCSV] = useState("");
  const [types, setTypes] = useState([]);
  const [activeUspsType, setActiveUspsType] = useState({});
  const [uspsValidateDisabled, setUspsValidateDisabled] = useState(false);
  const [Weight, setWeight] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [activeType, setActiveType] = useState("");
  // table data
  const [csvOrders, setCsvOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  // const resetForm = () => {
  //   setCsvpaydisabled(true)
  //   setCsvPrice(0)
  //   setCSV("")
  //   setActiveUspsType({})
  //   setWeight(0)
  //   setFinalPrice(0)
  // }
  const [incomings, setIncomings] = useState({});
  useEffect(() => {
    fetchIncomings();
  }, []);

  const fetchIncomings = async () => {
    await axios
      .get(server + "/v1/users/stats", config)
      .then((res) => {
        setIncomings(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    readLabelTypes();
  }, []);

  useEffect(() => {
    readCsvOrders();
  }, [page]);

  const readLabelTypes = async () => {
    await axios
      .get(server + "/v1/labeltype/read?init=", config)
      .then((res) => {
        setTypes(res.data.labelTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateCSV = async (e) => {
    e.preventDefault();

    if (!activeUspsType) {
      toast.error("Please select a label type");
      return;
    }

    setValidateLoader(true);

    const params = new FormData();
    params.append("csv", CSV);
    params.append("type", activeUspsType._id);

    await axios
      .post(server + "/v1/order/validatecsv", params, config)
      .then((res) => {
        setValidateLoader(false);
        setCsvPrice(res.data.totalPrice);
        setCsvpaydisabled(false);
        toast.success("CSV validation Successfully");
      })
      .catch((err) => {
        setValidateLoader(false);
        toast.error(err.response?.data?.message);
      });
  };

  const createOrderFromCSV = async (e) => {
    e.preventDefault();
    setCsvpaydisabled(true);
    setOrdersLoading(true);

    const params = new FormData();
    params.append("csv", CSV);
    params.append("type", activeUspsType._id);
    params.append("weight", Weight);
    params.append("total_price", csvPrice);
    if (parseInt(incomings.balance) <= 0) {
      setCsvpaydisabled(true);
      setCSV("");
      readCsvOrders();
      setWeight(0);
      setActiveType("");
      setCsvPrice(0);
      setOrdersLoading(false);
      toast.error("Not enough balance");
      return;
    }
    await axios
      .post(server + "/v1/order/createOrderFromCSV", params, config)
      .then((res) => {
        toast.success("Orders created successfully");
        setCsvpaydisabled(true);
        setCSV("");
        readCsvOrders();
        setWeight(0);
        setActiveType("");
        setCsvPrice(0);
        setOrdersLoading(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        setOrdersLoading(false);
      });
  };

  const readCsvOrders = async () => {
    setOrdersLoading(true);
    await axios
      .get(`${server}/v1/order/readcsvOrders?page=${page}&limit=7`, config)
      .then((res) => {
        setTotalPages(res.data.totalPages);
        setCsvOrders(res.data.csvOrders);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setOrdersLoading(false));
  };

  const downloadZip = async (id) => {
    await axios
      .get(`${server}/v1/order/bulk-download/${id}`, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.zip"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };
  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="Bulk Labels" />
      <Row>
        <Col xs={12}>
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h4 className="page-title text-white">Bulk Orders</h4>
          </div>
        </Col>
      </Row>
      <Row className="g-4">
        <Col xs={12} md={4} lg={4}>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box">
                    <div className="page-title-right"></div>
                    <h4 className="page-title text-white">
                      Create Bulk Orders
                    </h4>
                  </div>
                </Col>
              </Row>
              <Row className="">
                <Col xs={12} md={12} lg={12} className="w-100 mb-3">
                  <select
                    name="labeltype"
                    className="w-100 form-control"
                    placeholder="Select Type *"
                    onChange={(e) => {
                      setActiveUspsType(
                        types.find((type) => type._id === e.target.value)
                      );
                    }}
                  >
                    <option value="">Select type</option>
                    {types.map((type) => (
                      <option key={type._id} value={type._id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </Col>
                {/* <Col xs={12} md={12} lg={12} className="mb-3">
                  <InputGroup className="mb-0">
                    <Form.Control
                      placeholder="Package Weight (70 Lbs Max)*"
                      className="input-field"
                      type="number"
                      value={Weight}
                      onChange={(e) => setWeight(e.target.value)}
                      required
                    />
                    <InputGroup.Text
                      style={{ width: "auto", borderLeft: "none" }}
                      className="input-field"
                    >
                      Lbs
                    </InputGroup.Text>
                  </InputGroup>
                </Col> */}
                <Col xs={12} md={12} lg={12} className="mb-3 text-center w-100">
                  <Button className="c-btn btn-primary w-100 px-1">
                    <label
                      htmlFor="csv"
                      className="fs-sm d-flex justify-content-center gap-2 align-items-center"
                    >
                      <FontAwesomeIcon icon={faUpload} />
                      {CSV?.name || "Click to upload CSV File"}
                    </label>
                    <input
                      accept=".csv"
                      type="file"
                      id="csv"
                      className="d-none"
                      name="csv"
                      required
                      onChange={(e) => {
                        setCSV(e.target.files[0]);
                      }}
                    />
                  </Button>
                </Col>
                <Col xs={12} md={12} lg={12} className="mb-3 text-center w-100">
                  <div className="d-flex flex-column flex-lg-row gap-3 justify-content-between">
                    <Button
                      className="c-btn btn-info w-auto px-1"
                      onClick={() => (window.location.href = "/sample.csv")}
                    >
                      <FontAwesomeIcon icon={faDownload} /> Download Sample
                    </Button>
                    <Button
                      disabled={uspsValidateDisabled}
                      onClick={validateCSV}
                      className="c-btn btn-warning w-auto px-1"
                    >
                      {uspsValidateDisabled ? (
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faCheckSquare} />
                      )}
                      Validate CSV
                    </Button>
                  </div>
                </Col>
              </Row>

              <Stack
                direction="horizontal"
                className="justify-content-between border-top border-dark pt-3"
              >
                <span className="">
                  Total:{" "}
                  <span className="text-info">
                    ${csvPrice ? csvPrice : "0.00"}
                  </span>
                </span>
                <Button
                  disabled={csvpaydisabled}
                  className="c-btn filled-btn w-auto px-5 "
                  onClick={createOrderFromCSV}
                >
                  {ordersLoading ? (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Pay"
                  )}
                </Button>
              </Stack>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box">
                    <div className="page-title-right"></div>
                    <h4 className="page-title text-white">Bulk Orders</h4>
                  </div>
                </Col>
              </Row>
              <div className="table-responsive" style={{ overflowY: "hidden" }}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Order Date</th>
                      <th>Orders</th>
                      <th>Labeltype</th>
                      <th>Price</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {csvOrders?.map((order, index) => (
                      <tr key={index}>
                        <td>{order._id}</td>
                        <td>{new Date(order.createdAt).toDateString()}</td>
                        <td>{order.orders?.length}</td>
                        <td>{order.labelType?.name}</td>
                        <td>${order.price}</td>
                        <td>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Download Zip</Tooltip>}
                          >
                            <Button
                              variant="warning"
                              size="small"
                              className="btn btn-sm"
                              onClick={() => downloadZip(order._id)}
                            >
                              <FontAwesomeIcon icon={faDownload} />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end mt-5">
                <nav>
                  <ul className="pagination">
                    <li
                      className={
                        page === 1 ? "page-item disabled" : "page-item"
                      }
                    >
                      <Link
                        className="page-link"
                        to=""
                        onClick={(e) => {
                          setPage(page - 1);
                        }}
                      >
                        Prev
                      </Link>
                    </li>

                    <li className="page-item active" aria-current="page">
                      <Link className="page-link" to="">
                        {page}
                      </Link>
                    </li>

                    <li
                      className={
                        page === totalPages ? "page-item disabled" : "page-item"
                      }
                    >
                      <Link
                        className="page-link"
                        to=""
                        tabIndex={-1}
                        aria-disabled={page === totalPages ? true : false}
                        onClick={(e) => {
                          setPage(page + 1);
                        }}
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CsvOrders;
