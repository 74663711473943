import React from "react";
import { server, RECAPTCHA_KEY } from "../env";
import axios from "axios";
import { useState } from "react";
import Cookies from "js-cookie";
import Loader from "../components/Loader";
import Alert from "../components/Alert";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState("");
  const [alert, setAlert] = useState("");

  // const refRecaptcha = React.useRef(null);

  const login = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    // await refRecaptcha.current.execute();

    const params = {
      password,
      username,
      // "g-recaptcha-response": await refRecaptcha.current.getResponse(),
    };

    await axios
      .post(server + "/v1/auth/user-login", params)
      .then((res) => {
        setAlert(<Alert className="success" message={res.data.message} />);
        Cookies.set("token", res.data.accessToken);
        localStorage.setItem("balance", res.data.balance);
        setLoader("");
        window.location.href = "/stats";
      })
      .catch((err) => {
        setAlert(
          <Alert className="danger" message={err.response?.data?.message} />
        );
        setLoader("");
      });

    setLoader("");
  };

  return (
    <div className="container-fluid px-3 bg-light">
      <div className="row">
        {/* End Col */}
        <div
          className="col-lg-12 d-flex justify-content-center align-items-center"
          style={{
            height: "100vh",
          }}
        >
          <div
            className="card p-4 w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1"
            style={{ maxWidth: "25rem" }}
          >
            {/* Form */}
            <form
              onSubmit={(e) => {
                login(e);
              }}
            >
              <div className="text-center">
                <div className="mb-5">
                  <h1 className="display-5">Sign in</h1>
                </div>
                {alert}
              </div>

              <div className="mb-4">
                <label className="form-label">Username</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="username"
                  tabIndex={1}
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="form-label w-100" tabIndex={0}>
                  <span className="d-flex justify-content-between align-items-center">
                    <span>Password</span>
                  </span>
                </label>
                <div
                  className="input-group input-group-merge"
                  data-hs-validation-validate-class
                >
                  <input
                    type={show ? "text" : "password"}
                    className="form-control form-control-lg"
                    name="password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    id="changePassTarget"
                    className="input-group-append input-group-text"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setShow(!show)}
                  >
                    <i
                      id="changePassIcon"
                      className={show ? "bi-eye-slash" : "bi-eye"}
                    />
                  </span>
                </div>
                <span className="invalid-feedback">
                  Please enter a valid password.
                </span>
              </div>
              {/* End Form */}
              {/* Form Check */}
              {/* <div className="form-check mb-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue
                  id="termsCheckbox"
                />
                <label className="form-check-label" htmlFor="termsCheckbox">
                  Remember me
                </label>
              </div> */}
              {/* End Form Check */}
              <div className="d-grid">
                <button type="submit" className="btn btn-primary btn-lg">
                  Sign in {loader}
                </button>
              </div>
            </form>
            {/* End Form */}
          </div>
        </div>
      </div>
      {/* <Recaptcha ref={refRecaptcha} sitekey={RECAPTCHA_KEY} /> */}
    </div>
  );
};

export default Login;
