import { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Requests from "../utils/requests";
import axios from "axios";
import { config, server } from "../env";
import Alert from "../components/Alert";
import Loader from "../components/Loader";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TelegramLoginButton from "react-telegram-login";
const request = new Requests();

const Stats = () => {
  const [incomings, setIncomings] = useState({});
  const [loaders, setLoaders] = useState(false);
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState("");
  const [ordersStats, setOrdersStats] = useState({});

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [url, setUrl] = useState("/v1/users/orders");
  const [statsData, setStatsData] = useState([]);
  const [total, setTotal] = useState(0);
  const [telegramData, setTelegramData] = useState({});
  const [balanceLimit, setBalanceLimit] = useState(0);
  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
    toast.success("Copied to clipboard!");
  };
  useEffect(() => {
    fetchIncomings();
    fetchOrdersStats();
  }, []);

  const fetchIncomings = async () => {
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .get(server + "/v1/users/stats", config)
      .then((res) => {
        setIncomings(res.data);
        setBalanceLimit(res.data.limit);

        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        setLoader("");
        setLoaders(false);
      });
  };
  const fetchOrdersStats = async () => {
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .get(server + "/v1/users/orders-stats", config)
      .then((res) => {
        setOrdersStats(res.data);

        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        setLoader("");
        setLoaders(false);
      });
  };

  useEffect(() => {
    fetchOrders();
  }, [page, limit]);
  useEffect(() => {
    fetchStats();
  }, []);
  const fetchOrders = async (search) => {
    var link = "";
    if (search) {
      link = url + "?page=" + page + "&limit=" + limit + "&search=" + search;
    } else {
      link = url + "?page=" + page + "&limit=" + limit;
    }
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .get(server + link, config)
      .then((res) => {
        setProducts(res.data.orders);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        setLoader("");
        setLoaders(false);
      });
  };

  const fetchStats = async () => {
    await axios
      .get(server + `/v1/users/labelStats`, config)
      .then((res) => {
        setStatsData(res.data.labelTypesData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTelegramResponse = (response) => {
    if (response !== null || response) {
      setTelegramData(response);
      connectToTelegram(response);
    }
  };
  const connectToTelegram = async (response) => {
    const data = {
      telegramID: response.id,
      telegramName: response.username,
      photo: response.photo_url,
    };
    await axios
      .post(server + "/v1/users/connectToTelegram", data, config)
      .then((res) => {
        toast.success(res.data.message);
        fetchIncomings();
      })
      .catch((err) => {
        toast.success(err.response.data.message);
        console.log(err);
      });
  };

  const balanceLimitTelegram = async (e) => {
    e.preventDefault();
    const data = {
      limit: balanceLimit,
    };
    await axios
      .post(server + "/v1/users/connectToTelegram", data, config)
      .then((res) => {
        toast.success("Balance Limit to Get Notification Updated");
      })
      .catch((err) => {
        toast.success(err.response.data.message);
        console.log(err);
      });
  };

  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="Stats" />
      <div className="row">
        <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
          {loaders ? (
            loader
          ) : (
            <span
              className="card card-hover-shadow h-100"
              style={{
                cursor: "pointer",
              }}
            >
              <div className="card-body">
                <h6 className="card-subtitle">Total Balance</h6>
                <div className="row align-items-center gx-2 mb-1">
                  <div className="col-6">
                    <h2 className="card-title text-inherit">
                      ${incomings.balance}
                    </h2>
                  </div>
                  <div className="col-6">
                    <div className="chartjs-custom" style={{ height: "3rem" }}>
                      <div className="w-100 text-end display-4">
                        <i className="bi-currency-dollar"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          )}
        </div>
        <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
          {loaders ? (
            loader
          ) : (
            <span
              className="card card-hover-shadow h-100"
              style={{
                cursor: "pointer",
              }}
            >
              <div className="card-body">
                <h6 className="card-subtitle">Total Spends</h6>
                <div className="row align-items-center gx-2 mb-1">
                  <div className="col-6">
                    <h2 className="card-title text-inherit">
                      ${incomings.spends}
                    </h2>
                  </div>
                  <div className="col-6">
                    <div className="chartjs-custom" style={{ height: "3rem" }}>
                      <div className="w-100 text-end display-4">
                        <i className="bi-currency-dollar"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          )}
        </div>
        <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
          {loaders ? (
            loader
          ) : (
            <span
              className="card card-hover-shadow h-100"
              style={{
                cursor: "pointer",
              }}
            >
              <div className="card-body">
                <h6 className="card-subtitle">Total Orders</h6>
                <div className="row align-items-center gx-2 mb-1">
                  <div className="col-6">
                    <h2 className="card-title text-inherit">
                      {incomings.orders}
                    </h2>
                  </div>
                  <div className="col-6">
                    <div className="chartjs-custom" style={{ height: "3rem" }}>
                      <div className="w-100 text-end display-4">
                        <i className="bi bi-calculator nav-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          )}
        </div>
        <div className="col-12 mb-5">
          <div className="card ">
            <div className="card-header">
              <div className="card-title ">
                <h4 className="title text-center ">Orders Count</h4>
              </div>
            </div>
            <div className="card-body row">
              <div className="col-sm-6 col-lg-3 mb-3 ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">TODAY</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            {ordersStats.today}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-cart-check-fill"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col-sm-6 col-lg-3 mb-3 ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">WEEK</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            {ordersStats.week}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-cart-check-fill"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col-sm-6 col-lg-3 mb-3 ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">MONTH</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            {ordersStats.month}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-cart-check-fill"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col-sm-6 col-lg-3 mb-3 ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">ALLTIME</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            {ordersStats.allTime}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-cart-check-fill"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 ">
          <div className="card mb-4">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center w-100">
                <h5 className="card-title">Label Type Stats</h5>
              </div>
            </div>
            <div className="card-body px-0 pb-0">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No.</th>
                      <th scope="col">Label Type</th>
                      <th scope="col">Order Count</th>
                    </tr>
                  </thead>

                  <tbody>
                    {statsData.length === 0 ? (
                      <tr>
                        <td colSpan="12" className="text-center">
                          <span className="text-center">No Orders</span>
                        </td>
                      </tr>
                    ) : (
                      statsData?.map((product, i) => (
                        <tr key={product._id}>
                          <td>{i + 1}</td>
                          <td>{product.labelType}</td>
                          <td>{product.count}</td>
                        </tr>
                      ))
                    )}
                    {loaders && (
                      <tr>
                        <td colSpan="12" className="text-center">
                          <div className="text-center">
                            <Loader />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
