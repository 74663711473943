import React, { useContext, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { states } from "../../utils/Contants";
import axios from "axios";
import Cookies from "js-cookie";
import Loader from "../../components/Loader";
import { config, server } from "../../env";
import { toast } from "react-toastify";
const AddAddress = ({ isOpen, onClose, getAddresses }) => {
  const [loading, setLoading] = useState("");
  const [selectedCountryFrom, setSelectedCountryFrom] = useState("");
  const [formdata, setFormData] = useState({
    name: "",
    country: "",
    phone: "",
    company: "",
    street: "",
    street2: "",
    zip: "",
    city: "",
    state: "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(<Loader />);
    await axios
      .post(server + "/v1/address/create", formdata, config)
      .then((res) => {
        getAddresses();
        setLoading("");
        toast.success(res?.data?.message);
        onClose();
      })
      .catch((err) => {
        setLoading("");
        toast.error(err.response?.data?.message);
      });
    setTimeout(() => {
      setLoading("");
    }, 3000);
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size={"xl"}
      centered
      className="p-2"
    >
      <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
        <Modal.Title id="modal-title">
          <h4 className="text-info"> Add New Address </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4 pt-0">
        <Form onSubmit={onSubmit}>
          {loading}
          <Row>
            <Col sm={6}>
              <FloatingLabel controlId="floatingSelect" label="Country">
                <Form.Select
                  type="select"
                  label="Country"
                  name="country"
                  onChange={(e) => {
                    setSelectedCountryFrom(e.target.value);
                    setFormData({ ...formdata, country: e.target.value });
                  }}
                  containerClass={"mb-3"}
                >
                  <option value="" selected disabled>
                    {" "}
                    States
                  </option>
                  <option value="US">United States</option>
                  {/* <option value="CA">Canada</option> */}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="Name *" className="mb-3">
                <Form.Control
                  type="text"
                  name="name"
                  onChange={(e) =>
                    setFormData({ ...formdata, name: e.target.value })
                  }
                  placeholder="Enter Name"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="Phone *" className="mb-3">
                <Form.Control
                  type="tel"
                  name="phone"
                  placeholder="Enter Phone *"
                  onChange={(e) =>
                    setFormData({ ...formdata, phone: e.target.value })
                  }
                />
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="Company" className="mb-3">
                <Form.Control
                  type="text"
                  name="company"
                  onChange={(e) =>
                    setFormData({ ...formdata, company: e.target.value })
                  }
                  placeholder="Enter Company Name"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="Address Line 1 *" className="mb-3">
                <Form.Control
                  type="text"
                  name="street"
                  placeholder="Enter Address Line 1"
                  onChange={(e) =>
                    setFormData({ ...formdata, street: e.target.value })
                  }
                />
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="Address Line 2" className="mb-3">
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setFormData({ ...formdata, street2: e.target.value })
                  }
                  name="street2"
                  placeholder="Enter Address Line 2"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="Zipcode *" className="mb-3">
                <Form.Control
                  type="text"
                  name="zip"
                  onChange={(e) =>
                    setFormData({ ...formdata, zip: e.target.value })
                  }
                  placeholder="Enter Zipcode"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="City *" className="mb-3">
                <Form.Control
                  type="text"
                  name="city"
                  onChange={(e) =>
                    setFormData({ ...formdata, city: e.target.value })
                  }
                  placeholder="Enter city"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="State *">
                <Form.Select
                  type="select"
                  name="state"
                  placeholder="Select State"
                  onChange={(e) =>
                    setFormData({ ...formdata, state: e.target.value })
                  }
                >
                  {selectedCountryFrom === "CA"
                    ? states
                        .filter((state) => state.Country === "CA")
                        .map((state, index) => (
                          <option key={index} value={state.ID}>
                            {state.Name}
                          </option>
                        ))
                    : states
                        .filter((state) => state.Country === "US")
                        .map((state, index) => (
                          <option key={index} value={state.ID}>
                            {state.Name}
                          </option>
                        ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={12} className="d-flex justify-content-between">
              <Button className="btn btn-light" onClick={onClose}>
                Close
              </Button>
              <Button
                variant="success"
                type="submit"
                className="btn btn-success"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddAddress;
