import React, { useState } from "react";
import { Link } from "react-router-dom";
const Sidebar = (props) => {
  const [tab, setTab] = useState(window.location.href.split("/")[3] || "/");

  const setMode = (mode) => {
    localStorage.setItem("themeMode", mode);
    if (mode === "light")
      document
        .getElementById("theme")
        .setAttribute("href", "/assets/css/theme.min.css");
    else
      document
        .getElementById("theme")
        .setAttribute("href", "/assets/css/theme-dark.min.css");
  };

  const hideSidebar = () => {
    const sidebar = document.getElementById("sidebar");
    if (sidebar) {
      sidebar.style.marginLeft = "-17.2rem";
    }
  };

  return (
    <aside
      id="sidebar"
      className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white  "
    >
      <div className="navbar-vertical-container">
        <div className="navbar-vertical-footer-offset">
          {/* Logo */}
          {/* <a className="navbar-brand text-center" href="/" aria-label="Front">
            <img
              className="navbar-brand-logo rounded"
              src="/assets/img/main-light.svg"
              alt="..."
            />
            <img
              className="navbar-brand-logo-mini"
              src="/assets/img/main-light.svg"
              alt="..."
            />
          </a> */}
          {/* <span className="navbar-brand text-center"> Labelsify</span> */}
          {/* 
          <button
            type="button"
            className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler"
            style={{ opacity: 1 }}
            onClick={hideSidebar}
            id="sidebarHideToggle"
          >
            <i
              className="bi-arrow-bar-left navbar-toggler-short-align"
              data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title=""
              data-bs-original-title="Collapse"
              aria-label="Collapse"
            ></i>
            <i
              className="bi-arrow-bar-right navbar-toggler-full-align"
              data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title=""
              data-bs-original-title="Expand"
              aria-label="Expand"
            ></i>
          </button> */}

          <div className="navbar-vertical-content">
            <div
              id="navbarVerticalMenu"
              className="nav nav-pills nav-vertical card-navbar-nav"
            >
              {/* Collapse */}
              <div className="nav-item">
                <Link
                  to="/stats"
                  className={tab === "stats" ? "nav-link active" : "nav-link"}
                  onClick={() => setTab("stats")}
                >
                  <i className="bi bi-calculator nav-icon" />
                  <span className="nav-link-title">Dashboard</span>
                </Link>
              </div>
              <div className="nav-item">
                <Link
                  to="/addresses"
                  className={
                    tab === "addresses" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => setTab("addresses")}
                >
                  <i class="bi bi-person-lines-fill nav-icon"></i>
                  <span className="nav-link-title">Addresses</span>
                </Link>
              </div>
              <div className="nav-item">
                <Link
                  to="/create-label"
                  className={
                    tab === "create-label" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => setTab("create-label")}
                >
                  <i class="bi bi-bookmark-check-fill nav-icon"></i>
                  <span className="nav-link-title">Create Label</span>
                </Link>
              </div>
              <div className="nav-item">
                <Link
                  to="/create-bulk-label"
                  className={
                    tab === "create-bulk-label" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => setTab("create-bulk-label")}
                >
                  <i class="bi bi-tags-fill nav-icon"></i>
                  <span className="nav-link-title">Create Bulk Labels</span>
                </Link>
              </div>
              <div className="nav-item">
                <Link
                  to="/orders"
                  className={tab === "orders" ? "nav-link active" : "nav-link"}
                  onClick={() => setTab("orders")}
                >
                  <i class="bi bi-card-checklist nav-icon"></i>
                  <span className="nav-link-title">Orders</span>
                </Link>
              </div>

              <div className="nav-item">
                <Link
                  to="/logs"
                  className={tab === "logs" ? "nav-link active" : "nav-link"}
                  onClick={() => setTab("logs")}
                >
                  <i class="bi bi-journal-check nav-icon"></i>
                  <span className="nav-link-title">Transaction Logs</span>
                </Link>
              </div>
              {/* <div className="nav-item">
                <Link
                  to="/deposit"
                  className={tab === "deposit" ? "nav-link active" : "nav-link"}
                  onClick={() => setTab("deposit")}
                >
                  <i class="bi bi-cash-stack nav-icon"></i>
                  <span className="nav-link-title">Deposit</span>
                </Link>
              </div> */}
            </div>
          </div>
          {/* End Content */}
          {/* Footer */}
          <div className="navbar-vertical-footer">
            <ul className="navbar-vertical-footer-list">
              <li className="navbar-vertical-footer-list-item">
                {/* Style Switcher */}
                <div className="dropdown dropup">
                  <button
                    type="button"
                    className="btn btn-ghost-secondary btn-icon rounded-circle"
                    id="selectThemeDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-dropdown-animation
                  >
                    <i className="bi-palette" />
                  </button>
                  <div
                    className="dropdown-menu navbar-dropdown-menu navbar-dropdown-menu-borderless"
                    aria-labelledby="selectThemeDropdown"
                  >
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      className="dropdown-item"
                      data-icon="bi-brightness-high"
                      data-value="default"
                      onClick={() => {
                        setMode("light");
                      }}
                    >
                      <i className="bi-brightness-high me-2" />
                      <span className="text-truncate">Light</span>
                    </span>
                    <span
                      className="dropdown-item"
                      data-icon="bi-moon"
                      data-value="dark"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setMode("dark");
                      }}
                    >
                      <i className="bi-moon me-2" />
                      <span className="text-truncate" title="Dark">
                        Dark
                      </span>
                    </span>
                  </div>
                </div>
                {/* End Style Switcher */}
              </li>
            </ul>
          </div>
          {/* End Footer */}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
