const Cookies = require("js-cookie");
var host = window.location.href;

module.exports = {
  server: host.includes("localhost")
    ? "http://localhost:7400/api"
    : "https://api.labelsify.com/api",

  config: {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  },

  // recaptcha keys
  RECAPTCHA_KEY: "6LcFUuAlAAAAAIpqPx1B4MLMxhvY9HInOhLOPQ4N",
  checkAccess: (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        Cookies.remove("token");
        window.location.href = "/";
      }
    }
    return true;
  },
};
