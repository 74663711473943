import { useEffect, useState } from "react";
import AuthRouter from "./routes/AuthRouter";
import DashboardRoutes from "./routes/DashboardRoutes";
import { Route, Routes } from "react-router-dom";
import axios from "axios";
import { server, config } from "./env";
import PageLoader from "./components/PageLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  useEffect(() => {
    setMode(localStorage.getItem("themeMode"));
  }, []);

  const [isLogin, setIsLogin] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    checkUserLogin();
  }, []);

  const checkUserLogin = async (token) => {
    await axios(server + "/v1/auth/user-access", config)
      .then((rsp) => {
        setIsLogin(true);
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsLogin(false);
        setIsLoaded(true);
      });
  };

  const setMode = (mode) => {
    localStorage.setItem("themeMode", mode);
    if (mode === "light")
      document
        .getElementById("theme")
        .setAttribute("href", "/assets/css/theme.min.css");
    else
      document
        .getElementById("theme")
        .setAttribute("href", "/assets/css/theme-dark.min.css");
  };

  return isLoaded ? (
    isLogin ? (
      <>
        <Routes>
          <Route path="/*" element={<DashboardRoutes />} />
        </Routes>
        <ToastContainer
          position="top-center"
          hideProgressBar
          draggable={false}
          theme="colored"
        />
      </>
    ) : (
      <>
        <Routes>
          <Route path="/*" element={<AuthRouter />} />
        </Routes>
        <ToastContainer
          position="top-center"
          hideProgressBar
          draggable={false}
          theme="colored"
        />
      </>
    )
  ) : (
    <PageLoader />
  );
}

export default App;
