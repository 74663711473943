import Loader from "./Loader";
export default function PageLoader() {
  return (
    <div
      className="d-flex w-100  justify-content-center align-items-center"
      style={{
        height: "100vh",
      }}
    >
      <div className="loader text-center">
        <Loader className="spinner-border-lg" />
      </div>
    </div>
  );
}
