import React, { Fragment, useEffect, useState } from "react";

import Header from "../components/Header";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import axios from "axios";
import { config, server } from "../env";
import Alert from "../components/Alert";
import { Link } from "react-router-dom";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";

const SoldProducts = () => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [itemInfo, setItemInfo] = useState({});
  const [loader, setLoader] = useState("");
  const [alert, setAlert] = useState("");
  const [loaders, setLoaders] = useState(false);
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [url, setUrl] = useState("/v1/admin/users/read");
  const [total, setTotal] = useState(0);

  const [showUser, setShowUser] = useState(false);
  const onCloseUserModal = () => {
    setShowUser(false);
  };
  const onOpenUserModal = () => setShowUser(true);
  useEffect(() => {
    fetchIncomings();
  }, [page, limit]);

  const fetchIncomings = async (search) => {
    var link = "";
    if (search) {
      link = url + "?page=" + page + "&limit=" + limit + "&search=" + search;
    } else {
      link = url + "?page=" + page + "&limit=" + limit;
    }
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .get(server + link, config)
      .then((res) => {
        setProducts(res.data.users);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        setLoader("");
        setLoaders(false);
      });
  };

  const createAPIkey = async () => {
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .post(server + "/v1/admin/users/create", {}, config)
      .then((res) => {
        fetchIncomings(res.data);

        toast.error(res.data.message);
        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        setLoader("");
        setLoaders(false);
      });
  };

  const disabledAPIkey = async (id) => {
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .put(server + "/v1/admin/disable-user/" + id, {}, config)
      .then((res) => {
        fetchIncomings(res.data);
        toast.success(res.data.message);
        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);

        setLoader("");
        setLoaders(false);
      });
  };

  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="product" />

      <div className="d-flex justify-content-end align-items-center w-100 mb-3">
        <button
          className="btn btn-primary rounded-3"
          data-bs-toggle="modal"
          data-bs-target="#create"
          onClick={createAPIkey}
        >
          <i className="bi-plus"></i>
          {loaders ? loader : "Add API key"}
        </button>
      </div>

      <>
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="card-title">Product</h5>
              <div className="btn-group align-items-center gap-2">
                <input
                  type="text"
                  className="form-control"
                  style={{
                    maxWidth: "200px",
                  }}
                  placeholder="Search..."
                  onChange={(e) => {
                    fetchIncomings(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="card-body px-0 pb-0">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th># ID</th>
                    <th>API KEY</th>
                    <th>Total Orders</th>
                    <th>Total Spends </th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {products &&
                    products?.map((product) => (
                      <tr key={product._id}>
                        <td>{product._id}</td>
                        <td>{product.api_key}</td>
                        <td>{product.orders}</td>
                        <td>${product.spends}</td>
                        <td>
                          {" "}
                          {product.status ? (
                            <Badge bg="success">Active</Badge>
                          ) : (
                            <Badge bg="danger">Inactive</Badge>
                          )}
                        </td>
                        <td>{new Date(product.createdAt).toDateString()}</td>
                        <td>
                          <div className="d-flex gap-2">
                            <Link
                              className="page-link"
                              to={`/orders/${product._id}`}
                            >
                              <button
                                className="btn btn-sm btn-info mr-2"
                                onClick={() => {
                                  onOpenUserModal();
                                }}
                              >
                                <i className="bi-eye"></i>
                              </button>
                            </Link>
                            {product.status ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    <b>Disable User</b>
                                  </Tooltip>
                                }
                              >
                                <button
                                  className="btn btn-sm btn-danger mr-2"
                                  onClick={() => {
                                    disabledAPIkey(product._id);
                                  }}
                                >
                                  <i className="bi-person-fill-slash"></i>
                                </button>
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    <b>Enable User</b>
                                  </Tooltip>
                                }
                              >
                                <button
                                  className="btn btn-sm btn-success mr-2"
                                  onClick={() => {
                                    disabledAPIkey(product._id);
                                  }}
                                >
                                  <i className="bi-person-check-fill"></i>
                                </button>
                              </OverlayTrigger>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  {loaders && (
                    <tr>
                      <td colSpan="8">
                        <div className="text-center">
                          <Loader />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <nav>
                <ul className="pagination">
                  <li
                    className={page === 1 ? "page-item disabled" : "page-item"}
                  >
                    <Link
                      className="page-link"
                      to=""
                      onClick={(e) => {
                        setPage(page - 1);
                      }}
                    >
                      Prev
                    </Link>
                  </li>

                  <li className="page-item active" aria-current="page">
                    <Link className="page-link" to="">
                      {page}
                    </Link>
                  </li>

                  <li
                    className={
                      page === totalPages ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link
                      className="page-link"
                      to=""
                      tabIndex={-1}
                      aria-disabled={page === totalPages ? true : false}
                      onClick={(e) => {
                        setPage(page + 1);
                      }}
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default SoldProducts;
